<template>
  <div class="home">
    <div class="container text-center">
      <div class="mb-5">
        <h2>
          Авторизация
        </h2>
      </div>
      <div class="row align-items-center">
        <div class="col-sm">
          <div class="dropdown">
            <button type='button' class="btn btn-default btn-lg btn-block dropdown-toggle border border-dark rounded-0"
                    id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ masterName.name }}
            </button>
            <div class="dropdown-menu rounded-0" aria-labelledby="dropdownMenu2" style="width: 100%;">
              <button v-for="master in masters" :key="master.id + 'master'" class="dropdown-item text-center"
                      type="button" @click="chooseMaster(master.id)" :id="master.id">
                {{ master.text }}
              </button>
            </div>
          </div>
        </div>

        <div class="col-sm">
          <div class="input-group input-group-lg ">
            <input type="password" placeholder="Пароль" class="form-control rounded-0" aria-label="Large"
                   v-model="password"
                   aria-describedby="inputGroup-sizing-sm">
          </div>
        </div>
        <div class="col-sm">
          <button class="btn btn-default btn-lg btn-block bg-warning text-black rounded" @click="auth">Войти</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Auth',
  props: {},
  data() {
    return {
      vue: "true",
      masters: [],
      currentMaster: null,
      password: "",
      masterName: {
        name: "Выберите мастера",
        id: 0
      }
    }
  },
  methods: {
    chooseMaster(id) {
      this.currentMaster = this.masters.filter(master => master.id === id)[0]
      console.log(this.currentMaster)
      this.masterName = {
        id: this.currentMaster.id,
        name: this.currentMaster.text
      }
    },
    auth() {
      if (this.currentMaster.id && this.password) {
        let fields = {
          IBLOCK_TYPE_ID: 'lists',
          IBLOCK_ID: '21',
          ELEMENT_ID: this.currentMaster.id,
          filter: {
            'PROPERTY_137': this.password
          }
        };
        fetch('https://corian-bitrix.complex-it.net/rest/3/j0p02a26u0zzmv3h/lists.element.get', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify(fields)
        })
            .then(response => response.json())
            .then(master_log => {
                  if (master_log.result.length > 0) {
                    this.$emit('update:appauth', true)
                    localStorage.id = master_log.result[0].ID
                    let rate = 0
                    for (rate in master_log.result[0].PROPERTY_135) {
                      localStorage.rate = master_log.result[0].PROPERTY_135[rate].replace(/[^\d.-]/g, '')
                    }
                    localStorage.name = master_log.result[0].NAME
                    if (this.$router.currentRoute.path !== '/') this.$router.push('/')
                  } else {
                    this.password = ''
                    alert('Неверный пароль')
                  }
                }
            );
        //localStorage.auth = true
        //this.$emit('update:appauth', true)
      } else {
        alert('Не выбран логин или пароль')
      }
    },
    load_masters(masters_bx) {
      masters_bx.forEach(master => {
        this.masters.push({
          id: master.ID,
          text: master.NAME
        })
      })
    }
  },
  mounted() {
    this.$nextTick(function () {

    })
  },
  created() {
    let fields = {
      IBLOCK_TYPE_ID: 'lists',
      IBLOCK_ID: '21'
    };
    fetch('https://corian-bitrix.complex-it.net/rest/3/j0p02a26u0zzmv3h/lists.element.get', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(fields)
    })
        .then(response => response.json())
        .then(masters_bx => this.load_masters(masters_bx.result)
        );
  }
}
</script>