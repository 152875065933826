<template>
  <div>
    <div class="container text-center">
      <div class="mb-3">
        <h2>
          {{ master_name }}
        </h2>
      </div>
      <div class="mb-5">
        <h2>
          Внесите время по проекту
        </h2>
      </div>
      <div class="row align-items-center justify-content-center">
        <nav class="nav">
          <router-link to="/" class="nav-link active">Мои проекты</router-link>
          <router-link to="/all" class="nav-link">Все проекты</router-link>
        </nav>
        <div class="data-input">
          <v-date-picker v-model="date" mode="date" color="yellow" :masks="{ input: ['DD.MM.YYYY']}">
            <template v-slot="{ inputValue, inputEvents }">
              Дата: <input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue"
                v-on="inputEvents"
            />
            </template>
          </v-date-picker>
        </div>
      </div>

      <div class="row align-items-center" v-for="(project,index) in projects" :key="project.id + 'project'">
        <div class="col-sm-3">
                    <span>
                      {{ project.name }}
                    </span>
        </div>
        <div class="col-sm-3">
          <div class="dropdown">
            <button class="btn btn-default btn-lg btn-block dropdown-toggle border rounded-0"
                    type="button" :id="'dropdownMenu'+index" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false"
                    v-bind:class="{disabled: project.send, 'border-danger': project.noTime, 'border-dark': !project.noTime }">
              {{ times_pick["id" + project.id] }}
            </button>
            <div class="dropdown-menu rounded-0" :aria-labelledby="'dropdownMenu'+index">
              <button v-for="time in times" class="dropdown-item text-center" type="button" :key="time.id + project.id"
                      v-bind:class="{disabled: project.send}"
                      @click="chooseTime(time.id,project.id)">
                {{ time.time }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="input-group input-group-lg">
            <input type="text" placeholder="Комментарий" class="form-control rounded-0" aria-label="Large"
                   :disabled="project.send === true"
                   aria-describedby="inputGroup-sizing-sm" v-model="project.comment"
                   v-bind:class="{'border-danger': project.stage}">
          </div>
        </div>
        <div class="col-sm-2">
          <button class="btn btn-default btn-lg btn-block bg-warning text-black rounded"
                  v-bind:class="{disabled: project.send}" data-toggle="modal" data-target="#confirmModal"
                  @click="save_project(project.id)">OK
          </button>
        </div>
      </div>
    </div>
    <modal name="modal-send" :width="300"
           :height="75"
           :adaptive="true">
      <div style="padding: 25px">Данные отправлены</div>
    </modal>
  </div>
</template>

<script>
import {faArrowCircleLeft} from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'Home',
  data() {
    return {
      date: new Date(),
      vue: "true",
      projects: [],
      times_pick: [],
      times: [
        {
          id: 1,
          time: '0.5 часа',
        },
        {
          id: 2,
          time: '1 час'
        },
        {
          id: 3,
          time: '1.5 часа'
        },
        {
          id: 4,
          time: '2 часа'
        },
        {
          id: 5,
          time: '2.5 часа'
        },
        {
          id: 6,
          time: '3 часа'
        },
        {
          id: 7,
          time: '3.5 часа'
        },
        {
          id: 8,
          time: '4 часа'
        },
        {
          id: 9,
          time: '4.5 часа'
        },
        {
          id:10,
          time: '5 часов'
        },
        {
          id: 11,
          time: '5.5 часов'
        },
        {
          id: 12,
          time: '6 часов'
        },
        {
          id: 13,
          time: '6.5 часов'
        },
        {
          id: 14,
          time: '7 часов'
        },
        {
          id: 15,
          time: '7.5 часов'
        },
        {
          id: 16,
          time: '8 часов'
        }, {
          id: 17,
          time: '8.5 часов'
        },
        {
          id: 18,
          time: '9 часов'
        },
        {
          id: 19,
          time: '9.5 часов'
        },
        {
          id: 20,
          time: '10 часов'
        },
        {
          id: 21,
          time: '10.5 часов'
        },
        {
          id: 22,
          time: '11 часов'
        },
        {
          id: 23,
          time: '11.5 часов'
        },
        {
          id: 24,
          time: '12 часов'
        },
      ],
      curTimeID: 0,
      time_chose: "Выберите время",
      master_name: localStorage.name
    }
  },
  computed: {
    appExit() {
      return faArrowCircleLeft
    }
  },
  methods: {
    show() {
      this.$modal.show('modal-send');
    },
    hide() {
      this.$modal.hide('modal-send');
    },
    name() {
      console.log(this.vue)
    },
    chooseTime(id, projectID) {
      let curID = this.times.filter(time => time.id === id)[0]
      this.$set(this.times_pick, "id" + projectID, curID.time)
      //this.curTime = this.curTimeID.time
    },
    load_project(projects) {
      // eslint-disable-next-line no-unused-vars
      let stage_id = false

      projects.result.forEach(project => {
        stage_id = project.STAGE_ID === "C2:9";
        this.projects.push({
          id: project.ID,
          name: project.TITLE,
          comment: "",
          send: false,
          noTime: false,
          stageID: stage_id,
          stage: false
        })
        this.$set(this.times_pick, "id" + project.ID, "Выберите время")
      })
    },
    async save_project(id) {
      console.log(id)
      let proj = this.projects.filter(proj => proj.id === id)[0]
      console.log(proj)
      if (id === "296" && proj.comment.length === 0) {
        proj.stage = true
        return;
      }
      if (proj.send) return;
      let time = (+this.times_pick['id' + proj.id].replace(/[^\d.-]/g, ''))
      console.log('time', time)
      if (time === 0) {
        proj.noTime = true
        return;
      } else {
        proj.noTime = false
      }
      /*if (proj.stageID && proj.comment.length === 0) {
        proj.stage = true
        return;
      }*/
      let fields = {
        id: +proj.id,
      }
      let get_deal_master = await fetch('https://corian-bitrix.complex-it.net/rest/3/j0p02a26u0zzmv3h/crm.deal.get', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(fields)
      });
      let deal_master = await get_deal_master.json();
      console.log(deal_master)
      let id_deal_product = null
      let project_number = deal_master.result.UF_CRM_1603358687729
      let id_deal_main = null
      let title_deal_main = null
      console.log('deal_main', deal_master.result.UF_CRM_1587418255)
      console.log('project_number', deal_master.result.UF_CRM_1603358687729)
      if (deal_master.result.UF_CRM_1587418255) {
        id_deal_product = +deal_master.result.UF_CRM_1587418255;
        let fields = {
          id: id_deal_product
        };
        let get_deal_main = await fetch('https://corian-bitrix.complex-it.net/rest/3/j0p02a26u0zzmv3h/crm.deal.get', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify(fields)
        })
        let deal_main = await get_deal_main.json()
        if (deal_main.result.UF_CRM_1587418255) {
          id_deal_main = deal_main.result.UF_CRM_1587418255
          title_deal_main = deal_main.result.TITLE
        } else {
          console.log('ID материнской сделки отсутвует', deal_master.result.UF_CRM_1587418255)
          title_deal_main = deal_master.result.TITLE
        }
      } else {
        console.log('ID изделия сделки отсутвует', deal_master.result.UF_CRM_1587418255)
        title_deal_main = deal_master.result.TITLE
      }
      console.log('id_deal_main', id_deal_main)
      console.log('id_deal_product', id_deal_product)
      let fields_add_list = {
        'IBLOCK_TYPE_ID': 'lists',
        'IBLOCK_ID': 22,
        'ELEMENT_CODE': id_deal_main + (+new Date()),
        'FIELDS': {
          'NAME': title_deal_main,
          'PROPERTY_138': this.date.toLocaleDateString('ru'),//Дата
          'PROPERTY_139': localStorage.id,//Мастер
          'PROPERTY_140': time,//Время
          'PROPERTY_141': localStorage.rate,//Ставка, руб.
          'PROPERTY_142': +localStorage.rate * time,//Сумма, руб.
          'PROPERTY_143': project_number,//№ заказа
          'PROPERTY_144': id_deal_main,//Проект
          'PROPERTY_145': id_deal_product,//Изделие
          'PROPERTY_146': proj.comment,//Комментарий
        }
      }
      let add_list_query = await fetch('https://corian-bitrix.complex-it.net/rest/3/j0p02a26u0zzmv3h/lists.element.add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(fields_add_list)
      })
      let list_query = await add_list_query.json()
      proj.send = true
      proj.noTime = false
      proj.stage = false
      console.log(list_query)
      this.show()
      setTimeout(()=>{
        this.hide()
      },1500)
    }
  },
  mounted() {
    this.projects.forEach(proj => {
      this.$set(this.times_pick, "id" + proj.id, "0.5 часа")
    })
  },
  created() {
    let fields = {
      filter: {
        CATEGORY_ID: 2,
        UF_CRM_1601541194: localStorage.id,
        STAGE_SEMANTIC_ID: "P"
      },
      select: ["ID", "TITLE", "STAGE_SEMANTIC_ID", "STAGE_ID"]
    };
    fetch('https://corian-bitrix.complex-it.net/rest/3/j0p02a26u0zzmv3h/crm.deal.list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(fields)
    })
        .then(response => response.json())
        .then(projects => {
          console.log(projects.total)
          console.log(projects)
          if (projects.total > 50) {
            let total = projects.total
            let start = 50
            // eslint-disable-next-line no-unused-vars
            let dataListFilter = {}
            while (start < total) {
              dataListFilter = {
                filter: {
                  CATEGORY_ID: 2,
                  UF_CRM_1601541194: localStorage.id
                },
                start: start,
                select: ["ID", "TITLE"]
              }

              fetch('https://corian-bitrix.complex-it.net/rest/3/j0p02a26u0zzmv3h/crm.deal.list', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(dataListFilter)
              })
                  .then(response => response.json())
                  .then(projects_more => {
                    projects_more.result.forEach(project_add => {
                      projects.result.push({
                        ID: project_add.ID,
                        TITLE: project_add.TITLE,
                        STAGE_ID: project_add.STAGE_ID,
                      })
                    })
                  })
              //$getDeals['next'] = $getDeals_add['next'];
              start = start + 50
            }
          }
          this.load_project(projects)
        });
  }
}

</script>

<style>
.data-input {
  text-align-last: center;
}

.fas {
  position: absolute;
  transform: scale(-1, 1);
  font-size: 30px;
  vertical-align: sub;
  margin-right: 50px;
  color: #ffc105;
}

.header .exit {
  margin-left: 40px;
  color: #ffc105;
  font-size: 20px;
}

.container {
  margin-top: 200px;
}

.row {
  margin-bottom: 5%;
}

.dropdown-menu {
  position: absolute;
  width: 50%;
  left: 50px;
  top: 100px;
  z-index: 3;
  transform: translate(0, -50%);
  background: #fff !important;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

}

.col-sm {
  margin-bottom: 10%;
}


.nav {
  width: 960px;

  margin: 0 auto;
}

.nav a {
  display: table;
  margin: 0 auto 30px;
  font-size: 20px;
}

.nav a:hover {
  color: #ffc105;
}

.nav-link {
  color: black;
}

.active {
  color: #ffc105;
}


.input-group input:focus {
  border-radius: 0;
}

.input-group input {
  border-radius: 0;
  border: 1px solid black;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}


</style>
