<template>
  <div id="app">
    <template v-if="isAuth">
      <div class="header">
        <a href="#">
          <font-awesome-icon :icon="appExit" class="fas"/>
        </a>
        <a class='exit' href="#" @click="logout">Выйти</a>
      </div>
      <!--<div id="nav">
        <router-link to="/">Home</router-link>
        |
        <router-link to="/about">About</router-link>
      </div>-->
      <router-view/>
    </template>
    <template v-else>
      <Auth v-bind:appauth.sync="isAuth"/>
    </template>
  </div>
</template>


<script>
import Auth from "@/components/Auth";
import {faArrowCircleLeft, faWindowClose} from '@fortawesome/free-solid-svg-icons'
export default {
  name: 'Home',
  components: {Auth},
  data() {
    return {
      isAuth: false
    }
  },
  mounted() {
    console.log('login',this.isAuth)
  },
  methods:{
    logout(){
      localStorage.id = null
      localStorage.name = null
      localStorage.rate = null
      this.isAuth = false
    }
  },
  computed: {
    appExit() {
      return faArrowCircleLeft
    },
    clearSearch() {
      return faWindowClose
    }
  },
}
</script>

<style>

.header {
  position: fixed;
  width: 150px;
  margin-top: 30px;
  float: right;
  top: 0;
  right: 0;
}

#app {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
